<template>
    <div>
        <header class="flex-col header-navbar sumWidth">
            <img src="../assets/materialLibrarylogo.png" alt="" class="materialLibrarylogo">
            <p> 一站式赛事素材服务平台1111</p>
        </header>
        <main>
            <!-- 轮播 -->
            <div class="swiper">
                <div class="swiperBox" id="swiper" :style="{ height: scrollerHeight }">
                    <el-carousel>
                        <el-carousel-item v-for="(item, index) in ppttrack" :key="index">
                            <a :href="item.url" target="_blank" v-if="item.type == 2">
                                <img class="bannImage" id="imagBox" :src="item.image" alt="" @click="addUrl(item)">
                            </a>
                            <img class="bannImage" id="imagBox" :src="item.image" alt="" @click="addUrl(item)">
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>

            <div class="CompetitionEntrance">
                <div>
                    <img src="../assets/tu-1.png" alt="">
                    <h2>中国国际“互联网+”大学生创新创业大赛备赛素材</h2>
                    <p>依据最新赛事规则，由近百位具有丰富国赛金奖材料服务经验的设计师共同打造，专注高教、职教、红旅三大重点赛道各组别，使项目备赛BP（Word、PPT、VCR）材料高效呈现，助力参赛团队在“互联网+”赛事中取得更好成绩。
                    </p>
                    <button>下载素材</button>
                </div>
                <div>
                    <img src="../assets/tu-2.png" alt="">
                    <h2>“挑战杯”中国大学生创业计划竞赛备赛素材</h2>
                    <p>近十年“大挑”（全国大学生课外学术科技作品竞赛）、“小挑”（中国大学生创业计划竞赛）备赛材料服务经验；以评委视角把握优秀项目呈现方式，从项目书到辅助材料，沉淀众多不同类别，为项目材料撰写提供最佳模版选择，敬请期待。
                    </p>
                    <button style="opacity: .5;">敬请期待</button>
                </div>
                <div>
                    <img src="../assets/tu-3.png" alt="">
                    <h2>全国大学生职业规划大赛</h2>
                    <p>筑梦青春志在四方、规划启航指引未来。为备赛团队提供专业资料素材服务，成就首届大赛参赛者，敬请期待。
                    </p>
                    <button style="opacity: .5;">敬请期待</button>
                </div>
            </div>
        </main>
        <footer>
            陕公网安备61019002001772号陕ICP备2021008899号    Copyright © 2024 课之一（西安）科技有限公司，All Rights Reserved.
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ppttrack: [],
            screenWidth: document.body.clientWidth,
            scrollerHeight: 0 + 'px',
        }
    },
    methods: {
        getapp() {
            //获取轮播图
            this.axios.indexAd({
            }).then(res => {
                if (res.data.code == 0) {
                    this.ppttrack = res.data.data;
                    this.swiperBox();
                } else {
                    this.ppttrack = []
                }
            }).catch(err => {

            })
            this.axios.indexPage({

            }).then(res => {
                if (res.data.code == 0) {
                    this.artiList = res.data.data.ppt;
                    this.wordList = res.data.data.word;
                    this.swiperBox();
                }
            }).catch(err => {

            })
        },
        swiperBox() {
            const that = this
            var height = document.getElementById('imagBox').offsetHeight;
            that.scrollerHeight = height + 'px'
        },

    },
    watch: {
        screenWidth(val) {
            this.swiperBox()
        }
    },
    mounted() {
        this.getapp()
        this.$nextTick(() => {
            setTimeout(() => {
                this.swiperBox()
            }, 1000)
        })
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    }
}

</script>

<style>
* {
    font-size: 16px;
    user-select: none;
}

.sumWidth {
    /* width: 1400px; */
    margin: auto;
}

.flex-col {
    display: flex;
}

.header-navbar {
    height: 32px;
    justify-content: space-between;
    margin: 20px auto;
}

.swiper {
    /* min-width: 1400px; */
    position: relative;
}

.swiper img {
    width: 100%;
}

.CompetitionEntrance {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 30px;
}

.CompetitionEntrance>div {
    background: #FFFFFF;
    box-shadow: 0px 0px 35px 0px rgba(43, 42, 43, 0.15);
    border-radius: 6px;
    box-sizing: border-box;
    padding: 1%;
    width: 447px;
    min-width: 400px;
    height: 450px;
    position: relative;
    cursor: pointer;
    margin: 10px;
}

.CompetitionEntrance>div:hover {
    box-shadow: 0px 0px 35px 0px rgba(43, 42, 43, 0.26);
}

.CompetitionEntrance img {
    width: 99%;
    height: 148px;
}

.CompetitionEntrance h2 {
    width: 99%;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 28px;
}

.CompetitionEntrance p {
    width: 99%;
    height: 100px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-top: 15px;
    text-indent: 2rem;
    overflow: hidden;
}

.CompetitionEntrance button {
    width: 110px;
    height: 30px;
    background: linear-gradient(90deg, #28D4E1, #13A4E6);
    border-radius: 15px;
    border: 0;
    color: #fff;
    font-size: 14px;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 55px);
}

footer {
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-top: 33px;
}
</style>
<style>
.seamless-warp {
    height: 80px;
    margin-bottom: 17px;
    overflow: hidden;
}

.swiperBox .el-carousel__indicators {
    opacity: 0;
}

.swiperBox .el-carousel {
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.swiperBox .el-carousel__container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>
